import React, {useState, useEffect} from "react"
import { Link } from "gatsby"
import {document, window} from "browser-monads"

import UbuntuIcon from '../assets/svg/ubuntu-icon.svg';
import UbuntuLogo from '../assets/svg/ubuntu-logo.svg';

const Header = () => {
  const
    [visible, setVisible] = useState(false),
    { pathname } = window.location,
    body = document.body;
  let timeout

  useEffect(() => {
    const
      header = document.getElementsByTagName("header"),
      main = document.getElementsByTagName("main"),
      cursor = document.getElementById('cursor');

    if(visible) {
      document.getElementsByTagName('body')[0].classList.add('overflow-none')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-none')
    }

    body.addEventListener('mousemove', e => {
      const
        target = e.target,
        isSelectable = target.classList.contains("clickable"),
        isHovered = cursor.classList.contains("hoveredCursor");

        cursor.style.left = `${e.pageX-5}px`
        cursor.style.top = `${e.pageY-5}px`

      if(isSelectable && !isHovered) {
        cursor.classList.add('hoveredCursor');
      } else if(!isSelectable && isHovered) {
        cursor.classList.remove('hoveredCursor');
      }

      if (cursor.classList.contains('stopped')) {
        cursor.classList.remove('stopped');
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cursor.classList.add('stopped');
      }, 3500);

    });

    body.addEventListener('mouseleave', () => {
      cursor.classList.add('disappear')
    });

    body.addEventListener('mouseenter', () => {
      cursor.classList.remove('disappear')
    });

    if(visible && pathname !== "/live-ubuntu") {
      header[0].style.position = "fixed"
      main[0].style.paddingTop = "152px"
    } else if(!visible && pathname !== "/live-ubuntu") {
      header[0].style.position = "unset"
      main[0].style.paddingTop = "60px"
    }

  })

  return(
    <header>
      <Link to="/live-ubuntu" className="clickable live-ubuntu"><UbuntuIcon className="header--icon clickable"/></Link>
      {pathname === "/home" || pathname === "/home/" ? (
        <a href="https://splash.experienceubuntu.com" className="clickable"><UbuntuLogo className="header--logo clickable"/></a>
      ) : (
        <Link to="/home" className="clickable"><UbuntuLogo className="header--logo clickable"/></Link>
      )}
      <div className={visible ? "header--nav clickable open" : "header--nav clickable"} onClick={() => setVisible(!visible)}>
        <span className={visible ? "nav--menu open" : "nav--menu"}></span>
        <span className={visible ? "nav--menu open" : "nav--menu"}></span>
      </div>
      <div id="cursor"></div>
        <div id="menu" className={visible ? "open" : "close"}>
          <nav>
            <ul>
              <li><Link to="/home" className="clickable">Home</Link></li>
              <li><Link to="/team" className="clickable">Team</Link></li>
              <li><Link to="/services" className="clickable">Services</Link></li>
              <li><Link to="/contact"  className="clickable">Contact</Link></li>
            </ul>
          </nav>
          <div className="links">
            <a target="__blank" href="https://www.instagram.com/experienceubuntu/" className="clickable">IG</a>
            <a target="__blank" href="https://www.youtube.com/channel/UCWjRfWna9fDhtkF2-fww24w?view_as=subscriber" className="clickable">YT</a>
          </div>
        </div>
    </header>
  )
}

export default Header
